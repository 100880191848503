import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Two(){
    return (
        <>

            <Container fluid className="__bg-secondary">
                <Container>
                    <Row className="fade-in">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__image_sad_woman">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/women-sec-atas.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__text-desc">
                                <h2 className="__text-headline __text-drak">
                                Pernahkah kamu?
                                </h2>
                                <p className="text-left">
                                    Punya banyak kenalan pria tapi gak tau cara meningkatkan ketertarikan ke level berikutnya, 
                                </p>
                                <p className="text-left">
                                    Ada sih, gebetan yang disuka tapi dia gak suka balik sama kamu… 
                                </p>
                                <p className="text-left">
                                    Bingung mau ungkapkan perasaan duluan, takut ditolak lalu bikin dia menjauh.. 
                                </p>
                                <p className="text-left">
                                    Sementara pria yang mendekat malah bukan pria yang kamu suka..uughh 
                                </p>
                                <p className="text-left">
                                    Sebenarnya, gak ada salahnya kamu punya banyak kenalan pria  
                                </p>
                                <p className="text-left">
                                    Namun, jika dihitung.. Berapa banyak pria yang lanjut dan mau bertahan?
                                </p>
                                <p className="text-left">
                                    Dan masih banyak lagi kondisi membingungkan dalam kehidupan
                                    romansamusebab kamu tidak mengerti <b>strategi buat dia tertarik balik
                                    denganmu tanpa nembak dan tanpa takut ditolak</b> hingga ingin
                                    membahagiakan dan menaklukkan hatimu!.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    )
}